
const MyWatermark = 'my-watermark'

/**
 * en先渲染未进主页的一个页面，再渲染app的页面，所以这里不能放到指定元素，只能放到所有页面
 * @param {string} word 水印文本
 * @returns 
 */
const setWatermark = (word) => {
    if (word == ' ') return
    if (document.getElementById(MyWatermark) !== null) {
        document.body.removeChild(document.getElementById(MyWatermark));
    }

    let theme = document.documentElement.getAttribute('data-theme-mode');
    let opacity = theme == 'dark' ? 0.2 : 0.27

    const can = document.createElement('canvas');
    can.width = 250;
    can.height = 180;
    const cans = can.getContext('2d');
    cans.rotate((-22 * Math.PI) / 180);
    cans.font = '1rem Vedana';
    cans.fillStyle = `rgba(200, 200, 200, ${opacity})`;
    cans.textBaseline = 'middle';
    cans.fillText(word, can.width / 10, can.height / 2);

    let div = document.createElement('div');
    div.id = MyWatermark
    div.style.width = `${document.documentElement.clientWidth}px`;
    div.style.height = `${document.documentElement.clientHeight}px`;
    div.style.top = '0px';
    div.style.left = '0px';
    div.style.position = 'fixed';
    document.body.appendChild(div);

    div.style.pointerEvents = 'none';
    div.style.zIndex = '99';
    div.style.background = `url(${can.toDataURL('image/png')}) left top repeat`;
};

setWatermark(getWord());

function getWord() {
    if (!frappe) return
    // frappe.ready(function() {
    //     var current_company = frappe.defaults.get_default('company');
    //     console.log("当前公司:", current_company);
        
    //     // 如果你需要获取更多关于公司的信息，比如地址、电话等
    //     frappe.call({
    //         method: "frappe.client.get",
    //         args: {
    //             doctype: "Company",
    //             name: current_company
    //         },
    //         callback: function(r) {
    //             if (!r.exc) {
    //                 var company_doc = r.message;
    //                 console.log("公司详细信息:", company_doc);
    //                 // 在这里你可以访问 company_doc 中的任何字段，例如：company_doc.address, company_doc.phone 等
    //             }
    //         }
    //     });
    // });
   //  let company = frappe.defaults.get_default('company') || ''
 //  let username = frappe.boot.user.first_name || ''
    // let username = frappe.defaults.get_default('user') || ''
    return `${company} ${username}`
}

window.onload = () => {
    const body = document.documentElement;
    const config = { attributes: true, childList: true, subtree: true };
    const callback = function(mutationsList, observer) {
        let wm = document.getElementById(MyWatermark)
        // Use traditional 'for loops' for IE 11
        for(let mutation of mutationsList) {
            mutation.removedNodes.forEach(function (item) {
                if (item && item.id === MyWatermark) {
                    setWatermark(getWord())
                    return
                }
            });
            // if (mutation.type === 'childList') {
            //     console.log('A child node has been added or removed.');
            // }
            if (wm && mutation.attributeName === 'class') {
                let cl = wm.classList
                if (cl && cl.length > 0) {
                    cl.forEach(e => wm.classList.remove(e))
                }
            }
            if (mutation.type === 'attributes') {
                if(wm && wm.style.display == 'none') {
                    wm.style.display = 'block'
                }
                if (mutation.attributeName === 'data-theme-mode') {
                    setWatermark(getWord())
                    observer.disconnect()
                    observer.observe(body, config)
                }
            }
        }
    };
    const observer = new MutationObserver(callback);
    observer.observe(body, config);
    // observer.disconnect()

    // hidden: Get more insights with Frappe Insights
    // let ls = document.querySelector('.row.layout-main .list-sidebar')
    // console.log(ls)
}
